<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mt-6 mr-3"
        fab
        depressed
        outlined
        x-small
        color="hqsOrange"
        v-bind="attrs"
        v-on="on"
        @click="prepareInfo"
      >
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>
    </template>

    <v-card>
      <Header :headerTitle="headerTitle" :hasMenu="hasMenu" />
      <v-card-text>
        {{ info }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const Header = () => import('../general/Header.vue')

export default {
  components: {
    Header,
  },

  data: () => ({
    hasMenu: false,
    dialog: false,
  }),

  props: {
    headerTitle: String,
    info: String,
  },

  methods: {
    prepareInfo() {},
  },

  computed: {},
}
</script>

<style scoped></style>
